<template>
  <div class="main is-flex animate__animated animate__fadeIn">
    <div class="left is-flex is-flex-direction-column is-align-items-center">
      <img class="logo" src="./assets/hover-logo.svg" />
      <img class="shadow" src="./assets/shadow.svg" />
    </div>
    <div class="words is-flex is-align-items-center is-justify-content-center is-flex-direction-column">
      <h1 class="title has-text-weight-light">Hover Labs</h1>
      <a target="_blank" rel="noopener" href="mailto:hello@hover.engineering?subject=Hello+Friends!" class="button">Get In Touch</a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style type="text/scss" lang="scss">
  @import './assets/sass/globals';
  @import '~bulma/bulma';
  @import '~animate.css/animate.css';

  @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;300&display=fallback');

  .has-text-weight-thin{
    font-weight: 100;
  }

  .main{
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    @include until($tablet){
      flex-direction: column;
    }
  }

  .words{
    .title{
      font-size: 3.5rem;
    }
    .subtitle{
      font-size: 2.5rem;
    }
  }
  .left{
    img{
      max-width: 12rem;
      max-height: 15rem;
      &.logo{
        animation: hover 2.5s ease-in-out infinite;
        margin-right: 2rem;
      }
      &.shadow{
        padding: 0 1rem;
        animation: breath 2.5s ease-in-out infinite;
        max-width: 10rem;
      }
    }
  }

  @keyframes breath {
    0%,100% {
      transform: scale(0.85);
    }
    50% {
      transform: scale(1);
    }
  }

  @keyframes hover {
    0%,100% {
      transform: translateY(-.5rem);
    }
    50% {
      transform: translateY(0px);
    }
  }


</style>
